import React from 'react'

const SvgPointFeature3 = props => (
  <svg id='point-feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.point-feature3_svg__st0{fill:#334966}'}</style>
    <g id='point-feature3_svg__\u63A5\u5165\u65B9\u4FBF-48x48'>
      <path
        id='point-feature3_svg__Combined-Shape_20_'
        className='point-feature3_svg__st0'
        d='M30 41.04v-2h17v-32H3v32h17v2H1v-36h48v36H30z'
      />
      <path id='point-feature3_svg__Rectangle-17-Copy' className='point-feature3_svg__st0' d='M13 45.04h24v2H13z' />
      <path
        id='point-feature3_svg__Combined-Shape-Copy'
        d='M18 32.04c-3.87 0-7-3.13-7-7s3.13-7 7-7c.1 0 .19 0 .29.01.86-2.89 3.54-5.01 6.71-5.01 3.87 0 7 3.13 7 7v.08c.33-.05.66-.08 1-.08 3.31 0 6 2.69 6 6s-2.69 6-6 6c-.34 0-.67-.03-1-.08v.08H18z'
        fill='#fd5c1f'
      />
      <path
        id='point-feature3_svg__Combined-Shape_19_'
        className='point-feature3_svg__st0'
        d='M24 24.3l-3.5 3.73-1.5-1.6 6-6.4 6 6.4-1.5 1.6L26 24.3v22.73h-2V24.3z'
      />
    </g>
  </svg>
)

export default SvgPointFeature3
