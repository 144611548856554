import styled, { css } from 'styled-components'
import ProductBg from '../../images/index/product_bg.png'
import { Flex } from '@raysync/common/components'

export const ProductBoxWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const ProductBox = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    box-sizing: border-box;
    width: 384px;
    height: 220px;
    padding: 30px 30px 10px 34px;
    margin-bottom: ${spacing.large};
    background: url(${ProductBg}) no-repeat;
    background-position: 100% 100%;
    box-shadow: 0px 0px 12px 0px rgba(24, 144, 255, 0.2);
    text-align: center;
    &:not(:nth-child(3n)) {
      margin-right: 24px;
    }
    p {
      padding: 20px 0 2px 0;
      min-height: 109px;
      line-height: 20px;
      font-size: ${typography.textThin};
      color: ${color.text};
    }
    svg {
      width: 48px;
      height: 48px;
    }
  `}
`

export const ApplicationSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 65px 0 90px 0;
    background-color: ${color.base};
  `}
`

export const SvgContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.base};
  `}
`

export const ProcessItem = styled.span`
  ${({ theme: { color } }) => css`
    display: block;
    width: 260px;
    height: 80px;
    background-color: #ebf5fa;
    margin-bottom: 2px;
    text-align: center;
    border: 1px solid #ebf0f3;
    h4 {
      line-height: 80px;
      font-size: 16px;
      color: ${color.text};
      padding-bottom: 20px;
    }
    ${p =>
      p.active &&
      css`
        background-color: white !important;
        h4 {
          color: ${color.secondary} !important;
        }
        border-left: 5px solid ${color.secondary} !important;
      `}
  `}
`

export const ProcessContainerRight = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 920px;
    height: 326px;
    text-align: center;
    border: 1px solid #ebf0f3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    p {
      color: ${color.text};
      font-size: ${typography.textSmall};
    }
    img {
      width: 80%;
    }
  `}
`

export const ProcessContainer = styled(Flex)`
  justify-content: space-between;
`
export const ArchSection = styled.div`
  padding: 63px 0 110px 0;
  svg {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
`
