import React, { useRef } from 'react'
import { useHover } from '@raysync/common/hooks'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Wrapper, H3 } from '@raysync/common/components'
import Layout from '../../components/layout'

import { FeatureSection } from './atoms'
import {
  ProductBoxWrap,
  ProductBox,
  ApplicationSection,
  SvgContainer,
  ProcessItem,
  ProcessContainer,
  ProcessContainerRight,
  ArchSection,
} from './point.atoms'

import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/network/point/point-feature1'
import Feature2 from '@raysync/common/components/svg/network/point/point-feature2'
import Feature3 from '@raysync/common/components/svg/network/point/point-feature3'
import Feature4 from '@raysync/common/components/svg/network/point/point-feature4'
import Feature5 from '@raysync/common/components/svg/network/point/point-feature5'
import Feature6 from '@raysync/common/components/svg/network/point/point-feature6'

import Feature7 from '@raysync/common/components/svg/network/point/point-feature7'
import Feature8 from '@raysync/common/components/svg/network/point/point-feature8'
import Feature9 from '@raysync/common/components/svg/network/point/point-feature9'
import Feature10 from '@raysync/common/components/svg/network/point/point-feature10'
import Feature11 from '@raysync/common/components/svg/network/point/point-feature11'
import Feature12 from '@raysync/common/components/svg/network/point/point-feature12'

import Banner from '@raysync/common/components/svg/network/point/banner'

import Img1 from '../../images/network/point/line_1.svg'
import Img2 from '../../images/network/point/line_2.svg'
import Img3 from '../../images/network/point/line_3.svg'
import Img4 from '../../images/network/point/line_4.svg'

const MainFuncItem = ({ onTrigger, children, active }) => {
  const ref = useRef()
  useHover(ref, onTrigger, true)
  return (
    <ProcessItem ref={ref} active={active}>
      {children}
    </ProcessItem>
  )
}

class Point extends React.PureComponent {
  state = {
    //产品功能列表
    featureList: [
      {
        title: intl.get('network3.feature.item1.title'),
        desc: intl.get('network3.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('network3.feature.item2.title'),
        desc: intl.get('network3.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('network3.feature.item3.title'),
        desc: intl.get('network3.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('network3.feature.item4.title'),
        desc: intl.get('network3.feature.item4.desc'),
        svg: <Feature4 />,
      },
      {
        title: intl.get('network3.feature.item5.title'),
        desc: intl.get('network3.feature.item5.desc'),
        svg: <Feature5 />,
      },
      {
        title: intl.get('network3.feature.item6.title'),
        desc: intl.get('network3.feature.item6.desc'),
        svg: <Feature6 />,
      },
    ],
    //产品优势列表
    featureListTwo: [
      {
        title: intl.get('network3.feature.item7.title'),
        desc: intl.get('network3.feature.item7.desc'),
        svg: <Feature7 />,
      },
      {
        title: intl.get('network3.feature.item8.title'),
        desc: intl.get('network3.feature.item8.desc'),
        svg: <Feature8 />,
      },
      {
        title: intl.get('network3.feature.item9.title'),
        desc: intl.get('network3.feature.item9.desc'),
        svg: <Feature9 />,
      },
      {
        title: intl.get('network3.feature.item10.title'),
        desc: intl.get('network3.feature.item10.desc'),
        svg: <Feature10 />,
      },
      {
        title: intl.get('network3.feature.item11.title'),
        desc: intl.get('network3.feature.item11.desc'),
        svg: <Feature11 />,
      },
      {
        title: intl.get('network3.feature.item12.title'),
        desc: intl.get('network3.feature.item12.desc'),
        svg: <Feature12 />,
      },
    ],

    activedImg: Img1,
    activedDesc: intl.get('network3.func.item1.desc'),
    active: 0,
    funcList: [
      {
        title: intl.get('network3.func.item1.title'),
        desc: intl.get('network3.func.item1.desc'),
        img: Img1,
      },
      {
        title: intl.get('network3.func.item2.title'),
        desc: intl.get('network3.func.item2.desc'),
        img: Img2,
      },
      {
        title: intl.get('network3.func.item3.title'),
        desc: intl.get('network3.func.item3.desc'),
        img: Img3,
      },
      {
        title: intl.get('network3.func.item4.title'),
        desc: intl.get('network3.func.item4.desc'),
        img: Img4,
      },
    ],
  }

  handleTrigger(index) {
    this.setState({ activedImg: this.state.funcList[index].img })
    this.setState({ activedDesc: this.state.funcList[index].desc })
    this.setState({ active: index })
  }

  componentWillReceiveProps() {
    this.setState({ active: 0 })
  }

  render() {
    const { location } = this.props
    const { featureList, featureListTwo, funcList, active, activedImg, activedDesc } = this.state

    return (
      <Layout pageType='network3' location={location} scaled>
        {/* 第一屏(企业点对点专线) */}
        <Swiper>
          <SolutionBanner type='network3' pic={<Banner />} />
        </Swiper>

        {/* 第二屏(产品优势) */}
        <ApplicationSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network3.scenes.title')}</CommonTitle>
            <ProductBoxWrap>
              {featureListTwo.map((l, i) => (
                <ProductBox key={i}>
                  <SvgContainer>{l.svg}</SvgContainer>
                  <div>
                    <H3>{l.title}</H3>
                    <p>{l.desc}</p>
                  </div>
                </ProductBox>
              ))}
            </ProductBoxWrap>
          </Wrapper>
        </ApplicationSection>

        {/* 第三屏(产品功能) */}
        <FeatureSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network3.feature.title')}</CommonTitle>
            <List data={featureList} />
          </Wrapper>
        </FeatureSection>

        {/* 第四屏(应用场景) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network3.arch.title')}</CommonTitle>
            <ProcessContainer>
              <div>
                {funcList.map((l, i) => (
                  <MainFuncItem key={i} onTrigger={this.handleTrigger.bind(this, i)} active={active === i}>
                    <div>
                      <h4>{l.title}</h4>
                    </div>
                  </MainFuncItem>
                ))}
              </div>
              <ProcessContainerRight>
                <p>{activedDesc}</p>
                <img src={activedImg} alt='feature' />
              </ProcessContainerRight>
            </ProcessContainer>
          </Wrapper>
        </ArchSection>

        {/* 第五屏(申请免费试用) */}
        <Swiper>
          <InviteBanner type='network3' />
        </Swiper>
      </Layout>
    )
  }
}

export default Point
