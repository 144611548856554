import React from 'react'

const SvgPointFeature1 = props => (
  <svg id='point-feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>
      {
        '.point-feature1_svg__st0,.point-feature1_svg__st2{stroke:#2b4b67;stroke-width:2;stroke-miterlimit:10}.point-feature1_svg__st0{fill:none;stroke-linecap:round}.point-feature1_svg__st2{fill:#fd5c1f}'
      }
    </style>
    <path
      className='point-feature1_svg__st0'
      d='M12.78 5.72C16.17 3.38 20.28 2 24.72 2c6.67 0 12.61 3.11 16.45 7.95M15.72 41.99c-7.1-3.37-12-10.6-12-18.98 0-2.18.33-4.29.95-6.27M45.71 23c0 10.24-7.33 18.77-17.02 20.63'
    />
    <ellipse cx={24.72} cy={23} rx={6.61} ry={6.62} fill='#fd5c1f' />
    <circle className='point-feature1_svg__st2' cx={24.72} cy={44.02} r={3.98} />
    <circle className='point-feature1_svg__st2' cx={6.54} cy={13.1} r={3.98} />
    <circle className='point-feature1_svg__st2' cx={43.46} cy={13.34} r={3.98} />
  </svg>
)

export default SvgPointFeature1
