import React from 'react'

const SvgPointFeature12 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <circle cx={25} cy={25.15} r={23} fill='none' stroke='#334966' strokeWidth={2} />
    <path fill='#fd5c1f' d='M25 29l12 6-12 6-12-6z' />
    <path
      fill='#334966'
      d='M34 22.85v-2h-6.51l6-6-1.42-1.41L25 20.51l-7.07-7.07-1.42 1.41 6 6H16v2h8v4h-8v2h8v8h2v-8h8v-2h-8v-4z'
    />
  </svg>
)

export default SvgPointFeature12
