import React from 'react'

const SvgPointFeature2 = props => (
  <svg id='point-feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>
      {
        '.point-feature2_svg__st0,.point-feature2_svg__st2{fill:none;stroke:#334966;stroke-width:2}.point-feature2_svg__st2{stroke-linecap:square}'
      }
    </style>
    <g id='point-feature2_svg__\u771F_1_'>
      <g id='point-feature2_svg__\u817E\u8BAF\u4E91-\u5929\u67A2_1_' transform='translate(-295 -858)'>
        <g id='point-feature2_svg__features_1_' transform='translate(0 718)'>
          <g id='point-feature2_svg__features-grid_1_' transform='translate(143 140)'>
            <g id='point-feature2_svg__feature_1_'>
              <g id='point-feature2_svg__icon' transform='translate(153)'>
                <g id='point-feature2_svg__features_icon_1'>
                  <path
                    id='point-feature2_svg__Polygon_2_'
                    className='point-feature2_svg__st0'
                    d='M47 25L35.5 44.92h-23L1 25 12.5 5.08h23z'
                  />
                  <path id='point-feature2_svg__Rectangle_6_' fill='#fd5c1f' d='M19 23.5l5-3.5h10v14H24l-5-3.5z' />
                  <path
                    id='point-feature2_svg__Rectangle_5_'
                    className='point-feature2_svg__st0'
                    d='M17 21.52v6.96L21.98 32H32V18H21.98L17 21.52z'
                  />
                  <path id='point-feature2_svg__Line' className='point-feature2_svg__st2' d='M16 25h-4.93' />
                  <path id='point-feature2_svg__Line_1_' className='point-feature2_svg__st2' d='M36 23h-3.94' />
                  <path id='point-feature2_svg__Line_2_' className='point-feature2_svg__st2' d='M36 27h-3.94' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgPointFeature2
