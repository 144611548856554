import React from 'react'

const SvgPointFeature6 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path
      d='M49 18v-2h-7v-6c0-1.1-.9-2-2-2h-6V1h-2v7h-6V1h-2v7h-6V1h-2v7h-6c-1.1 0-2 .9-2 2v6H1v2h7v6H1v2h7v6H1v2h7v6c0 1.1.9 2 2 2h6v7h2v-7h6v7h2v-7h6v7h2v-7h6c1.1 0 2-.9 2-2v-6h7v-2h-7v-6h7v-2h-7v-6h7zm-9 20c0 1.1-.9 2-2 2H12c-1.1 0-2-.9-2-2V12c0-1.1.9-2 2-2h26c1.1 0 2 .9 2 2v26z'
      fill='#334966'
    />
    <path
      d='M30 18H20c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V20c0-1.1-.9-2-2-2zm-2 12h-6c-2 0-2-.9-2-2v-6c0-1.1-.1-2 2-2h6c1.1 0 2-.1 2 2v6c0 1.1.1 2-2 2z'
      fill='#fd5c1f'
    />
  </svg>
)

export default SvgPointFeature6
