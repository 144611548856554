import React from 'react'

const SvgPointFeature4 = props => (
  <svg id='point-feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.point-feature4_svg__st0{fill:#2b4b67}.point-feature4_svg__st1{fill:#fd5c1f}'}</style>
    <path
      id='point-feature4_svg__\u5F62\u72B6_1_'
      className='point-feature4_svg__st0'
      d='M15 11h20v28H15V11zm2 2v24h16V13H17z'
    />
    <path id='point-feature4_svg__\u77E9\u5F62_2_' className='point-feature4_svg__st1' d='M19 15h12v8H19z' />
    <path className='point-feature4_svg__st1' d='M19 25h12v2H19zM19 29h12v2H19zM19 33h12v2H19z' />
    <path
      className='point-feature4_svg__st0'
      d='M5.8 35.75l5.06.56-4.19 4.19C-1.41 30.97-.83 16.84 8 8.01s22.97-9.42 32.5-1.34l-1.42 1.42c-8.15-6.8-19.99-6.81-28.16-.03S.6 26.5 5.8 35.75zM44 14.25l-5.06-.56 4.19-4.19c8.08 9.53 7.5 23.66-1.33 32.49s-22.97 9.42-32.5 1.34l1.42-1.42c8.15 6.8 19.99 6.81 28.16.03S49.2 23.5 44 14.25z'
    />
  </svg>
)

export default SvgPointFeature4
