import React from 'react'

const SvgPointFeature5 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path
      d='M47 40.17V9.82C48.16 9.4 49 8.3 49 7c0-1.66-1.34-3-3-3-1.3 0-2.4.84-2.82 2H6.8C6.4 4.8 5.3 4 4 4 2.3 4 1 5.3 1 7c0 1.34.82 2.43 2 2.83v30.36C1.84 40.6 1 41.7 1 43c0 1.66 1.34 3 3 3 1.3 0 2.4-.84 2.82-2H43.2c.4 1.2 1.5 2 2.8 2 1.7 0 3-1.3 3-3 0-1.34-.82-2.43-2-2.83zM43.2 42H6.82A3.01 3.01 0 005 40.18V9.83c.84-.3 1.51-.96 1.8-1.83h36.38c.3.85.97 1.51 1.82 1.82v30.36c-.84.29-1.51.95-1.8 1.82z'
      fill='#2b4b67'
    />
    <path
      d='M18 29h4v-2h-4v2zm15-8h-4v2h4v-2zm5 15V14H12v22h26zM14 23h11v-2H14v-5h22v11H25v2h11v5H14V23z'
      fill='#e95219'
    />
  </svg>
)

export default SvgPointFeature5
