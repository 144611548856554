import React from 'react'

const SvgPointFeature8 = props => (
  <svg id='point-feature8_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.point-feature8_svg__st1{fill:#fd5c1f}.point-feature8_svg__st2{fill:#2b4b67}'}</style>
    <path
      d='M43.74 10.93L27.32 1.7c-1.4-.78-3.68-.78-5.08 0L5.81 10.93c-1.42.8-2.54 2.69-2.54 4.31v19.51c0 1.62 1.12 3.51 2.54 4.31l16.44 9.23c.69.39 1.59.6 2.53.6.94 0 1.84-.21 2.53-.6l16.44-9.23c1.42-.8 2.54-2.69 2.54-4.31V15.23c-.01-1.61-1.13-3.5-2.55-4.3z'
      fill='none'
      stroke='#2b4b67'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      className='point-feature8_svg__st1'
      d='M28.82 8.86l-3.1-1.81c-.25-.14-.65-.14-.89 0l-3.1 1.82c-.28.16-.45.46-.45.79v3.63c0 .33.17.63.46.8l3.12 1.82a.848.848 0 00.84 0l3.12-1.82a.94.94 0 00.46-.8V9.65c0-.32-.18-.63-.46-.79z'
    />
    <path
      className='point-feature8_svg__st2'
      d='M25.28 18.09c-.44 0-.8.37-1 .82v7.46c.2.45.56.82 1 .82.44 0 .8-.37 1-.82v-7.46c-.2-.45-.56-.82-1-.82zM38.08 26.31c.14 0 .27-.03.4-.11.38-.22.52-.72.3-1.12-.16-.29-2.47-4.8-9.11-8.55a.79.79 0 00-1.09.32c-.21.4-.07.89.31 1.11 6.24 3.52 8.45 7.86 8.49 7.93a.8.8 0 00.7.42zM20.89 16.53c-6.64 3.75-8.94 8.26-9.11 8.55-.22.39-.08.89.3 1.12.12.07.26.11.4.11.28 0 .55-.15.7-.41.04-.07 2.25-4.41 8.49-7.93.39-.22.53-.72.31-1.11-.22-.4-.7-.54-1.09-.33z'
    />
    <path className='point-feature8_svg__st1' d='M36.28 28.88h6v6h-6zM22.28 28.88h6v6h-6zM8.28 28.88h6v6h-6z' />
  </svg>
)

export default SvgPointFeature8
