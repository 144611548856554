import React from 'react'

const SvgPointFeature10 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path d='M43 42.67v-4l6 6H1v-35l2 2v31h40zm-40-38h46v35l-2-2v-31H7v4l-6-6h2z' fill='#334966' />
    <circle cx={19} cy={24.67} r={9} fill='#fd5c1f' />
    <circle cx={31} cy={24.67} r={8} fill='none' stroke='#334966' strokeWidth={2} />
  </svg>
)

export default SvgPointFeature10
