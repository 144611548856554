import React from 'react'

const SvgPointFeature9 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path
      d='M37.55 38.97l-4.8-4.8C30.66 35.93 27.95 37 25 37c-2.88 0-5.53-1.02-7.59-2.71l-4.86 4.86c.29.55.45 1.18.45 1.85 0 .75-.21 1.46-.57 2.06C15.99 45.54 20.33 47 25 47s9.01-1.46 12.57-3.94c-.36-.6-.57-1.31-.57-2.06 0-.74.2-1.44.55-2.03zm1.42-1.42A3.98 3.98 0 0141 37c.75 0 1.46.21 2.06.57C45.54 34.01 47 29.67 47 25s-1.46-9.01-3.94-12.57c-.6.36-1.31.57-2.06.57-.67 0-1.3-.16-1.85-.45l-4.86 4.86A11.94 11.94 0 0137 25c0 2.95-1.07 5.66-2.84 7.75l4.81 4.8zm-27.76.11l4.76-4.76A11.933 11.933 0 0113 25c0-2.95 1.07-5.66 2.84-7.75l-4.8-4.8c-.6.35-1.3.55-2.04.55-.75 0-1.46-.21-2.06-.57C4.46 15.99 3 20.33 3 25s1.46 9.01 3.94 12.57C7.54 37.21 8.25 37 9 37c.82 0 1.57.24 2.21.66zm1.24-26.63l4.8 4.8C19.34 14.07 22.05 13 25 13c3.03 0 5.79 1.12 7.9 2.97l4.76-4.76A3.99 3.99 0 0137 9c0-.75.21-1.46.57-2.06C34.01 4.46 29.67 3 25 3s-9.01 1.46-12.57 3.94c.36.6.57 1.31.57 2.06 0 .74-.2 1.44-.55 2.03zM5.52 39.02C2.68 35.08 1 30.24 1 25s1.68-10.08 4.52-14.02C5.19 10.39 5 9.72 5 9c0-2.21 1.79-4 4-4 .72 0 1.39.19 1.98.52C14.92 2.68 19.76 1 25 1s10.08 1.68 14.02 4.52C39.61 5.19 40.28 5 41 5c2.21 0 4 1.79 4 4 0 .72-.19 1.39-.52 1.98C47.32 14.92 49 19.76 49 25s-1.68 10.08-4.52 14.02c.33.59.52 1.26.52 1.98 0 2.21-1.79 4-4 4-.72 0-1.39-.19-1.98-.52C35.08 47.32 30.24 49 25 49s-10.08-1.68-14.02-4.52c-.59.33-1.26.52-1.98.52-2.21 0-4-1.79-4-4 0-.72.19-1.39.52-1.98zM41 11c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9 11c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm32 32c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9 43c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm16-8c5.52 0 10-4.48 10-10s-4.48-10-10-10-10 4.48-10 10 4.48 10 10 10z'
      fill='#334966'
    />
    <circle cx={25} cy={25} r={8} fill='#fd5c1f' />
  </svg>
)

export default SvgPointFeature9
