import React from 'react'

const SvgPointFeature7 = props => (
  <svg id='point-feature7_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.point-feature7_svg__st0{fill:#334966}'}</style>
    <g id='point-feature7_svg__Group-8'>
      <path
        id='point-feature7_svg__Fill-4_3_'
        className='point-feature7_svg__st0'
        d='M25.44 12c-3.31 0-6 2.69-6 6v5h12v-5c0-3.31-2.69-6-6-6m0 2c2.2 0 4 1.79 4 4v3h-8v-3c0-2.21 1.79-4 4-4'
      />
      <path
        id='point-feature7_svg__Fill-6_2_'
        className='point-feature7_svg__st0'
        d='M25.44 1l-2.18 1.19A49.692 49.692 0 014.44 8v21.53c0 5.21 2.89 9.99 7.5 12.4L25.44 49l13.5-7.07c4.61-2.42 7.5-7.19 7.5-12.4V8c-6.6-.66-13-2.64-18.82-5.81L25.44 1zm0 2.28l1.22.66a51.488 51.488 0 0017.78 5.84v19.75c0 4.48-2.47 8.55-6.43 10.63l-12.57 6.58-12.57-6.58a11.96 11.96 0 01-6.43-10.63V9.78a51.07 51.07 0 0017.78-5.84l1.22-.66z'
      />
    </g>
    <path id='point-feature7_svg__Fill-2_4_' fill='#fd5c1f' d='M20.44 35h14V25h-14z' />
    <path className='point-feature7_svg__st0' d='M32.44 23v10h-14V23h14m2-2h-18v14h18V21z' />
  </svg>
)

export default SvgPointFeature7
